<template>
  <div>
    <h1
      class="font-primary text-[28px] font-bold leading-[40px] text-gray-1000 md:text-[40px] md:leading-[52px] lg:w-[1080px] lg:whitespace-nowrap"
    >
      <slot name="title" />
    </h1>
    <p
      class="mt-[16px] max-h-[120px] font-primary text-[16px] leading-[24px] text-gray-700 sm:h-auto md:mt-[8px] md:px-[40px] md:text-[20px] md:leading-[32px] lg:w-[1080px]"
    >
      <slot name="description" />
    </p>
  </div>
</template>
