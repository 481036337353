<script lang="ts" setup>
import HeroText from '@/components/product-shared/HeroText.vue'
import { testingType } from '../stores'
</script>

<template>
  <div class="relative mx-auto max-w-[1080px] pb-[40px] pt-[160px] text-center md:pt-[240px]">
    <HeroText v-if="testingType === 'component'" key="component">
      <template #title>
        Fast, easy, and reliable
        <span class="bg-gradient-to-r from-indigo-500 to-purple-500 bg-clip-text text-transparent">
          component testing
        </span>
      </template>
      <template #description>
        Build and test your UI components in isolation with Cypress for a more modern developer workflow. Test the
        intended behavior of your components in any browser with instant visual feedback.
      </template>
    </HeroText>
    <HeroText v-else-if="testingType === 'e2e'" key="e2e">
      <template #title>
        Test
        <span class="bg-gradient-to-r from-jade-500 to-indigo-500 bg-clip-text text-transparent"> modern apps </span>
        directly in your browser
      </template>
      <template #description>
        Build, test, and debug directly in your browser with a seamless developer experience that is loved by developers
        all around the world. Test your code, not your patience.
      </template>
    </HeroText>
  </div>
</template>
